// Override Bootstrap's Sass default variables
//
// Nearly all variables in Bootstrap are written with the `!default` flag.
// This allows you to override the default values of those variables before
// you import Bootstrap's source Sass files.
//
// Overriding the default variable values is the best way to customize your
// CSS without writing _new_ styles. For example, change you can either change
// `$body-color` or write more CSS that override's Bootstrap's CSS like so:
// `body { color: red; }`.


//
// Bring in Bootstrap
//

// Option 1
//
// Import all of Bootstrap's CSS

// @import "bootstrap/scss/bootstrap";

// Option 2
//
// Place variable overrides first, then import just the styles you need. Note that some stylesheets are required no matter what.

// Toggle global options
$enable-shadows: true;

// Customize some defaults
$primary: #438E83;
$secondary: #8f8673;
$info: #cc2165;
$light: #f3ede1;
$dark: #291E04;
$body-color: #5f4c26;
$card-border-radius: 0;

@import "bootstrap/scss/bootstrap";

//
// Custom styles
//